<template>
  <div class="w_100">
    <div class="item border_bottom" :class="marginTop" v-for="form in formData" :key="form.label">
      <div class="d_flex justify_content_between">
        <label class="form_label">{{ form.label }}</label>
        <input :type="form.type" class="form_control" :placeholder="form.placeholder" v-model="form.value" />
      </div>
    </div>
    <div class="btn_default btn_primary" @click="submitForm">
      {{ buttonContent }}
    </div>
    <div class="d_flex justify_content_center mt_1rem" @click="goToHelp" v-show="showHelpButton">
      <div class="icon">
        <img src="../../../assets/images/public/ic_my_money_aisle_raiders.webp" alt="" />
      </div>
      <div class="icon_title">教程攻略</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormContainer",
  props: {
    formData: {
      type: Array,
      default: () => [
        {
          label: "新密码",
          key: "password",
          type: "password",
          placeholder: "请输入新密码",
          value: "",
        },
      ],
    },
    buttonContent: {
      type: String,
      default: "确认修改",
    },
    showHelpButton: {
      type: Boolean,
      default: false
    },
    marginTop: {
      type: String,
      default: "mt_0rem",
    }
  },
  methods: {
    submitForm() {
      this.$emit("submitForm", this.formData);
    },
    goToHelp() {
      this.$emit("goToHelp")
    }
  },
};
</script>

<style lang="scss" scoped>
.w_100 {
  width: 100%;
}

.d_flex {
  display: flex;
  align-items: center;
  width: 100%;
}

.justify_content_between {
  justify-content: space-between;
}

.justify_content_center {
  justify-content: center;
}

.item {
  width: 100%;
  box-sizing: border-box;
}

.icon {
  display: block;
  width: 1.5rem;
  transform: translateY(0.1rem);

  &_title {
    color: var(--gray);
    font-size: .9rem;
    margin-left: .75rem;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.border_bottom {
  border-bottom: 0.75px solid #d4d4d481;
}

.form_label {
  margin-right: 0.5rem;
}

.form_control {
  flex: 1;
  padding: 0.75rem 0rem;
  border: none;
}

::placeholder {
  color: var(--light-gray);
  font-size: 1rem;
}

input:focus {
  outline: none;
}

.btn {
  &_default {
    display: inline-block;
    align-self: center;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: var(--light-gray);
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 1.25rem;
    width: 100%;
    margin-top: 3rem;
  }

  &_primary {
    color: white;
    background-color: var(--orange);
  }
}
</style>