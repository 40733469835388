<template>
  <div>
    <slot-header :showIcon="false" :showBackIcon="true" @onBack="goBack">
      <div>{{ $route.meta.name }}</div>
    </slot-header>
    <FormContainer v-if="payment" :formData="formData[payment]" buttonContent="确认" @submitForm="submitForm" @goToHelp="goToHelp" marginTop="mt_1rem" :showHelpButton="showHelpButton"></FormContainer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
// import Container from "../components/Container";
import FormContainer from "../components/FormContainer";
import SlotHeader from "@/components/SlotHeader";

export default {
  name: "security",
  components: {
    // Container,
    FormContainer,
    SlotHeader,
  },
  computed: {
    showHelpButton() {
      return this.payment !== "BankCard" ? true : false;
    },
    payment() {
      return this.$route.query.method;
    },
    channelId() {
      return this.payment ? this.paymentsId[this.payment].id : 0;
    },
  },
  data() {
    return {
      paymentsId: {
        alipay: {
          id: 1,
        },
        WeChat: {
          id: 2,
        },
        BankCard: {
          id: 3,
        },
      },
      formData: {
        alipay: [
          {
            label: "支付宝账号：",
            key: "account",
            type: "text",
            placeholder: "请输入支付宝账号",
            value: "",
          },
          {
            label: "真实姓名：",
            key: "accountName",
            type: "text",
            placeholder: "请输入姓名",
            value: "",
          },
          {
            label: "安全密码",
            key: "securityCode",
            type: "password",
            placeholder: "请输入云支付安全密码",
            value: "",
          },
        ],
        WeChat: [
          {
            label: "微信号：",
            key: "account",
            type: "text",
            placeholder: "请输入微信号",
            value: "",
          },
          {
            label: "手机号：",
            key: "accountMobile",
            type: "tel",
            placeholder: "请输入微信收款手机号",
            value: "",
          },
          {
            label: "真实姓名：",
            key: "accountName",
            type: "text",
            placeholder: "请输入姓名",
            value: "",
          },
          {
            label: "安全密码：",
            key: "securityCode",
            type: "password",
            placeholder: "请输入云支付安全密码",
            value: "",
          },
        ],
        BankCard: [
          {
            label: "开户行：",
            key: "bankName",
            type: "text",
            placeholder: "请输入开户行",
            value: "",
          },
          {
            label: "银行卡号：",
            key: "account",
            type: "text",
            placeholder: "请输入银行卡号",
            value: "",
          },
          {
            label: "真实姓名：",
            key: "accountName",
            type: "text",
            placeholder: "请输入姓名",
            value: "",
          },
          {
            label: "安全密码：",
            key: "securityCode",
            type: "password",
            placeholder: "请输入云支付安全密码",
            value: "",
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions("account", ["postAccount"]),
    goBack() {
      this.$router.push({
        path: "/my/paymentMethod/payment",
        query: { method: this.payment },
      });
    },
    submitForm(form) {
      let data = {};
      for (var i = 0; i < form.length; i++) {
        data[form[i].key] = form[i].value;
      }
      data.channelId = this.channelId;
      this.postAccount(data).then((res) => {
        if (res.code === "ok") {
          this.$router.go(-1);
        }
      });
    },
    goToHelp() {
      this.$router.push({
        path: "/my/paymentMethod/help",
        query: { method: this.payment },
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    let payments = {
      alipay: {
        title: "支付宝",
      },
      WeChat: {
        title: "微信",
      },
      BankCard: {
        title: "银行卡",
      },
    };
    to.meta.name = payments[to.query.method].title ? payments[to.query.method].title : "银行卡";
    next();
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
  padding: 0rem 1.2rem 0 1.2rem;
  box-sizing: border-box;
}

.d_flex {
  display: flex;
  align-items: center;
  width: 100%;
}

.justify_content_between {
  justify-content: space-between;
}

.item {
  width: 100%;
  box-sizing: border-box;
  box-sizing: border-box;
}

.icon {
  display: block;
  width: 1rem;
  transform: translateY(0.1rem);

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.border_bottom {
  border-bottom: 0.75px solid #d4d4d481;
}

.form_label {
  margin-right: 0.5rem;
}

.form_control {
  flex: 1;
  padding: 0.75rem 0rem;
  border: none;
}

::placeholder {
  color: var(--light-gray);
  font-size: 1rem;
}

input:focus {
  outline: none;
}

.btn {
  &_default {
    display: inline-block;
    align-self: center;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: var(--light-gray);
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 1.25rem;
    width: 80vw;
    margin-top: 5rem;
  }

  &_primary {
    color: white;
    background-color: var(--orange);
  }
}
</style>
